import { DataGrid, GridColDef } from '@mui/x-data-grid'
import './Glossary.css'

const Glossary = () => {
  const columns: GridColDef[] = [
    {
      field: 'item',
      headerName: 'Item',
      flex: 2,
    },
    {
      field: 'acronym',
      headerName: 'Acronym',
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 5,
    },
  ]

  const rows = [
    {
      id: 1,
      item: 'Emergency Location Identification Number',
      acronym: 'ELIN',
      description:
        'A phone number that is associated with a dispatchable location',
    },
    {
      id: 2,
      item: 'Presence Information Data Format - Location Object',
      acronym: 'PDIF-LO',
      description:
        'A standardised way of defining location information for transmission. As defined in RFC4119',
    },
    {
      id: 3,
      item: 'Calling Line Identifier',
      acronym: 'CLI',
      description:
        'The presentation number displayed by a party initiating a telephone call',
    },
    {
      id: 4,
      item: 'Local Area Network',
      acronym: 'LAN',
      description:
        'A local area network is a collection of devices connected in one physical location, such as a building, office, or home',
    },
    {
      id: 5,
      item: 'Base Service Set Identifier',
      acronym: 'BSSID',
      description: 'A hardware-based identifier for Wi-Fi networking equipment',
    },
    {
      id: 6,
      item: 'Public Switch Telephone Network',
      acronym: 'PSTN',
      description:
        'The public switched telephone network provides infrastructure and services for public telecommunication. The PSTN is the aggregate of the worlds circuit-switched telephone networks that are operated by national, regional, or local telephony operators',
    },
  ]

  return (
    <>
      <div className='glossary-container'>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[5]}
          checkboxSelection={false}
          disableRowSelectionOnClick
          autoHeight
          getRowId={(row) => row.id}
        />
      </div>
    </>
  )
}

export default Glossary
