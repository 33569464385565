import {
	Box,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Modal,
	Toolbar,
	Tooltip,
} from '@mui/material'
import { HashLink as Link } from 'react-router-hash-link'
import SipcomLogo from '../../assets/icons/sipcom_logo.png'
import Image1 from '../../assets/images/1.png'
import Image2 from '../../assets/images/2.png'
import Image3 from '../../assets/images/3.png'
import Image4 from '../../assets/images/4.png'
import Image5 from '../../assets/images/5.png'
import Image6 from '../../assets/images/6.png'
import Image7 from '../../assets/images/7.png'
import Image8 from '../../assets/images/8.png'
import Image9 from '../../assets/images/9.png'
import Image10 from '../../assets/images/10.png'
import Image11 from '../../assets/images/11.png'
import Image12 from '../../assets/images/12.png'
import Image13 from '../../assets/images/13.png'
import Glossary from '../../components/table/Glossary'
import { useState } from 'react'
import './Home.css'

const modalStyle = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	bgcolor: '#fff',
	boxShadow: 24,
	overflow: 'scroll',
	maxHeight: '80%',
}

const Home = () => {
	// General
	const [imageToEnlarge, setImageToEnlarge] = useState('')
	const [openModal, setOpenModal] = useState(false)

	// Side bar items
	const sideMenuItems = [
		{
			text: 'Scope',
			link: '#scope',
		},
		{
			text: 'Disclaimer',
			link: '#disclaimer',
		},
		{
			text: 'Introduction',
			link: '#introduction',
		},
		{
			text: 'Tenant Configuration',
			link: '#tenant-configuration',
		},
		{
			text: 'Additional Materials',
			link: '#additional-materials',
		},
		{
			text: 'Further Considerations',
			link: '#further-considerations',
		},
		{
			text: 'Glossary',
			link: '#glossary',
		},
		{
			text: 'Appendix 1 - Example of user experience for a call using dynamic E911',
			link: '#appendix-1',
		},
		{
			text: 'Appendix 2 - Client showing in a known location',
			link: '#appendix-2',
		},
		{
			text: 'Appendix 3 - Client in an unknown location',
			link: '#appendix-3',
		},
	]

	// Handle Functions
	const handleOpenModal = (image: string) => {
		setOpenModal(true)
		setImageToEnlarge(image)
	}

	const handleCloseModal = () => {
		setOpenModal(false)
		setImageToEnlarge('')
	}

	return (
		<>
			<Box className='home-container'>
				<Box className='home-content'>
					{/* Side menu */}
					<Box sx={{ display: 'flex' }}>
						<Drawer
							variant='permanent'
							className='drawer'
							sx={{
								width: 250,
								flexShrink: 0,
								[`& .MuiDrawer-paper`]: {
									width: 250,
									boxSizing: 'border-box',
									background: '#2e2e2e',
								},
							}}>
							<Toolbar>
								<h2 className='toolbar-h2'>Quick Links</h2>
							</Toolbar>
							<Box sx={{ overflow: 'auto' }}>
								<List>
									{sideMenuItems.map((item, index) => (
										<Link to={item.link} className='nav-link'>
											<ListItem key={index} disablePadding>
												<ListItemButton>
													<ListItemText primary={item.text} />
												</ListItemButton>
											</ListItem>
										</Link>
									))}
								</List>
							</Box>
						</Drawer>
						{/* Content */}
						<Box component='main' sx={{ flexGrow: 1, p: 3 }}>
							<Box className='home-header'>
								<img src={SipcomLogo} alt='Sipcom' />
								<h1>Guidance for Dynamic E911 for Direct Routing</h1>
							</Box>
							{/* Scope */}
							<Box className='home-text'>
								<h2 id='scope'>Scope</h2>
								<p>
									This document provides guidance on the steps to successfully
									configure and enable dynamic E911 service within a Microsoft
									Teams Phone System deployment that utilises Sipcom's Direct
									Routing platform and a compatible E911 PSTN carrier.
								</p>
								<p>
									The purpose of this webpage is to provide high-level guidance
									on the configuration.{' '}
									<strong>
										It is the enterprise's responsibility to ensure the
										configuration is compatible with their environment and carry
										out appropriate testing to ensure calls connect and provide
										the correct dispatchable location.
									</strong>
								</p>
							</Box>
							{/* Disclaimer */}
							<Box className='home-text'>
								<h2 id='disclaimer'>Disclaimer</h2>
								<p>
									"This webpage is confidential and privileged. It is intended
									solely for the use of the inBoxidual or entity to whom it is
									addressed and others authorized to receive it. Any disclosure,
									copying, distribution or taking any action in reliance on the
									contents of this information is strictly prohibited. The
									information contained on this webpage represents the current
									view of SIPCOM on the issues discussed as of the date of
									publication and is subject to change at any time without
									notice to you. This webpage and its contents are provided AS
									IS without warranty of any kind, and should not be interpreted
									as an offer or commitment on the part of SIPCOM or its
									resellers, and SIPCOM and its resellers cannot guarantee the
									accuracy of any information presented and you use at your own
									risk. SIPCOM and its resellers MAKE NO WARRANTIES, EXPRESS OR
									IMPLIED, ON THIS WEBPAGE."
								</p>
							</Box>
							{/* Introduction */}
							<Box className='home-text'>
								<h2 id='introduction'>Introduction</h2>
								<p>
									Since the introduction of the RAY BAUMs (Repack Airwaves
									Yielding Better Access for Users of Modern Services) Act,
									there is now a requirement for enterprises and organisations
									to provide a “dispatchable location” automatically to
									Emergency services when a 911 call is placed. Dispatchable
									locations must have a validated street address along with
									additional information to identify the caller's location such
									as floor number, building, or apartment.
								</p>
								<p>
									Since January 6, 2022, this requirement now applies to
									Multi-Line Telephone Systems (MLTS) that provide services to
									non-fixed devices and off-premises - such as Microsoft Teams
									Phone System.
								</p>
								<p>
									Microsoft offers two different mechanisms that support E911
									capabilities to facilitate compliance with the RAY BAUMs act
									and provide dynamic E911 services.
								</p>
								<ul>
									<li>
										Adding the dispatchable location to the SIP INVITE in the
										Presence Information Data Format - Location Object
										(PIDF-LO). The dispatchable location data is identified by
										the configured network details and then inserted within the
										PIDF-LO XML which is processed by the PSAP to route the
										call.
									</li>
									<li>
										Emergency Location Identification Number (ELIN) mapping.
										Each network location is configured with an ELIN number. For
										calls to 911 made from a known location with an ELIN, the
										CLI headers are re-written with the ELIN number and the
										mapping between the original CLI and ELIN number is cached
										to provide a return call path.
									</li>
								</ul>
								<p>
									Sipcom currently only supports the use of PIDF-LO to deliver
									dynamic E911 services. The guidance within this webpage guides
									on the PIDF-LO method.
								</p>
							</Box>
							{/* Tenant Configuration */}
							<Box className='home-text'>
								<h2 id='tenant-configuration'>Tenant Configuration</h2>
								<p>
									Several different elements need to be configured within the
									Microsoft 365 tenant to successfully enable dynamic E911.
									These elements are:
								</p>
								<ul>
									<li>Teams Tenant Trusted IP addresses</li>
									<li>Teams Emergency Addresses</li>
									<li>Teams Emergency Locations / Places</li>
									<li>
										Teams Network elements (IP Subnets, BSSID, Switches or
										switch ports)
									</li>
									<li>Teams Emergency Call Routing Policy</li>
								</ul>
								<p>
									Further detail on each element and its configuration can be
									found below:
								</p>
								<h4>Trusted IPs:</h4>
								<p>
									Trusted IPs are used to identify if the call is being made
									from a corporate network. If a call to emergency services is
									made from a corporate network, then the Microsoft Teams Phone
									system will take further steps to identify the location to
									populate the dispatchable location.
								</p>
								<p>
									Trusted IPs need to be configured for all public internet
									addresses that may be used at corporate locations. Entries can
									be single IPv4 addresses, IPv4 subnets, single IPv6 addresses
									or IPv6 subnets.
								</p>
								<p>Trusted IPs can be configured in the Teams Admin Centre:</p>
								{/* Image */}
								<Box className='image-container'>
									<Tooltip title='Click on image to enlarge it'>
										<IconButton onClick={() => handleOpenModal('1.png')}>
											<img src={Image1} alt='1' />
										</IconButton>
									</Tooltip>
								</Box>
								<h4>Emergency addresses</h4>
								<p>
									Emergency addresses are physical corporate locations that need
									to be configured with Microsoft 365. Microsoft provides
									further detail:
								</p>
								<p>
									“A location is a Civic Address—with an optional place. If your
									business has more than one physical location, it's likely that
									you'll need more than one emergency location.
								</p>
								<p>
									When you create an emergency address, a unique LocationID is
									automatically created for this address. If you add a place to
									an emergency address—for example, if you add a floor to a
									building address—a Location ID is created for the combination
									of the emergency address and place. In this example, there
									will be two Location IDs: one for the Civic Address; one for
									the joined Civic Address and associated place.
								</p>
								<p>
									When you assign an emergency location to a user or site, it's
									this unique location ID that's associated with the user or
									site.”
								</p>
								<p>
									Each corporate location will need to be deployed as an
									emergency address, with enterprises needing to consider
									whether child “places” need to be assigned to the emergency.
									Places provide a more accurate location within an emergency
									address. Examples of this would be a “place” for each floor
									within a building or different buildings within a campus.
								</p>
								<p>
									If an enterprise has more than one office, then each will need
									at least an emergency location and potentially “places”
									associated with areas within that office.
								</p>
								<p>Addresses are validated at the time of creation.</p>
								<p>
									Emergency addresses can be configured within the Teams Admin
									Centre:
								</p>
								{/* Image */}
								<Box className='image-container'>
									<Tooltip title='Click on image to enlarge it'>
										<IconButton onClick={() => handleOpenModal('2.png')}>
											<img src={Image2} alt='1' />
										</IconButton>
									</Tooltip>
								</Box>
								<p>Once configured, emergency addresses cannot be edited.</p>
								<h4>Places</h4>
								<p>
									Places provide more accurate information about a location
									within an emergency address. This would typically be a floor,
									room, building, suite or similar.
								</p>
								<p>
									Places are not mandatory for emergency locations, but
									enterprises need to consider if the emergency address is
									sufficiently accurate to be classed as a dispatchable
									location.
								</p>
								<p>
									Places can be configured in the Teams Admin Centre by first
									browsing to emergency addresses, then selecting the address
									that the place needs to be associated with:
								</p>
								{/* Image */}
								<Box className='image-container'>
									<Tooltip title='Click on image to enlarge it'>
										<IconButton onClick={() => handleOpenModal('3.png')}>
											<img src={Image3} alt='1' />
										</IconButton>
									</Tooltip>
								</Box>
								<p>Then it is possible to add the place</p>
								{/* Image */}
								<Box className='image-container'>
									<Tooltip title='Click on image to enlarge it'>
										<IconButton onClick={() => handleOpenModal('4.png')}>
											<img src={Image4} alt='1' />
										</IconButton>
									</Tooltip>
								</Box>
								<h4>Network elements</h4>
								<p>
									Once the location elements (Emergency addresses and places)
									have been configured, it is possible to associate the network
									elements at the location with them. Several different types of
									network identifiers can be associated with the location to
									identify the site when emergency calls are made:
								</p>
								<ul>
									<li>
										Subnet - the LAN subnet on the enterprise network at that
										location
									</li>
									<li>Wi-Fi BSSID</li>
									<li>Network switch chassis ID</li>
									<li>Port & network switch chassis ID</li>
								</ul>
								<p>
									Network elements can be associated with either the emergency
									address or the place. Be vigilant when deploying configuration
									to ensure it is on the correct location element.
								</p>
								<p>
									Network elements can be configured through the Teams Admin
									Centre. Emergency addresses:
								</p>
								{/* Image */}
								<Box className='image-container'>
									<Tooltip title='Click on image to enlarge it'>
										<IconButton onClick={() => handleOpenModal('5.png')}>
											<img src={Image5} alt='1' />
										</IconButton>
									</Tooltip>
								</Box>
								<p>Places:</p>
								{/* Image */}
								<Box className='image-container'>
									<Tooltip title='Click on image to enlarge it'>
										<IconButton onClick={() => handleOpenModal('6.png')}>
											<img src={Image6} alt='1' />
										</IconButton>
									</Tooltip>
								</Box>
								<h4>Emergency call routing policy</h4>
								<p>
									Emergency call routing policies are used to set up emergency
									numbers and specify how calls to the emergency numbers are
									routed. They also provide the capability to mask any numbers
									that users incorrectly dial to the correct number - for
									example, 9911 to 911.
								</p>
								<p>
									Each emergency number is bound to a PSTN usage that links to
									the Direct Routing trunks. The enterprise must ensure that the
									voice route and associated PSTN usage have a dial pattern that
									will match the 3-digit numbers being dialled, otherwise, the
									emergency services call will not be able to select the trunks
									and connect.
								</p>
								<p>
									Sipcom provides a standard set of PSTN usages with voice route
									and dialled number patterns that match US national and 3-digit
									numbers as part of the delivery of Direct Routing. This
									pattern is: <strong>^\+1\d*|^\d${'{3}'}</strong>
								</p>
								<p>
									It is essential that “dynamic emergency calling” is enabled on
									the emergency call routing policy.
								</p>
								<p>
									Emergency call routing policies can be configured within the
									Teams Admin Centre.
								</p>
								{/* Image */}
								<Box className='image-container'>
									<Tooltip title='Click on image to enlarge it'>
										<IconButton onClick={() => handleOpenModal('7.png')}>
											<img src={Image7} alt='1' />
										</IconButton>
									</Tooltip>
								</Box>
								{/* Image */}
								<Box className='image-container'>
									<Tooltip title='Click on image to enlarge it'>
										<IconButton onClick={() => handleOpenModal('8.png')}>
											<img src={Image8} alt='1' />
										</IconButton>
									</Tooltip>
								</Box>
								<p>
									Note: For Direct Routing, Teams clients no longer send
									emergency calls with a "+" in front of the emergency dial
									string. Be sure the voice route pattern to match an emergency
									dial string reflects this change.
								</p>
								<p>
									Most E911 providers have a 933 service to allow test calls to
									be made with the PIDF-LO data read out upon the call
									connected. 933 needs to be configured as an emergency dial
									string to enable this.
								</p>
								<p>
									Speak to your PSTN/E911 provider to find out if they offer
									this service.
								</p>
								<h4>
									Emergency Calling policy - External location lookup mode{' '}
								</h4>
								<p>
									The external location lookup mode setting enables end users to
									configure/confirm their emergency address. This can be enabled
									in the Teams Admin Centre:
								</p>
								{/* Image */}
								<Box className='image-container'>
									<Tooltip title='Click on image to enlarge it'>
										<IconButton onClick={() => handleOpenModal('9.png')}>
											<img src={Image9} alt='1' />
										</IconButton>
									</Tooltip>
								</Box>
								<p>
									Additional information regarding how emergency addresses are
									classified and routed by Microsoft may be found here:
								</p>
								<a href='https://learn.microsoft.com/en-us/microsoftteams/emergency-calling-dispatchable-location#emergency-address-classification-and-routing'>
									https://learn.microsoft.com/en-us/microsoftteams/emergency-calling-dispatchable-location#emergency-address-classification-and-routing
								</a>
								<h4>Policy Assignment</h4>
								<p>
									The emergency call routing policy must be assigned to each
									user requiring dynamic E911 capabilities. This can be done in
									the Teams Admin Centre:
								</p>
								{/* Image */}
								<Box className='image-container'>
									<Tooltip title='Click on image to enlarge it'>
										<IconButton onClick={() => handleOpenModal('10.png')}>
											<img src={Image10} alt='1' />
										</IconButton>
									</Tooltip>
								</Box>
							</Box>
							<Box className='home-text'>
								<h2 id='additional-materials'>Additional Materials</h2>
								<p>
									Microsoft has materials available to provide further details
									when planning and configuring dynamic E911:
								</p>
								<a href='https://learn.microsoft.com/en-us/microsoftteams/what-are-emergency-locations-addresses-and-call-routing'>
									https://learn.microsoft.com/en-us/microsoftteams/what-are-emergency-locations-addresses-and-call-routing
								</a>
							</Box>
							{/* Further Considerations */}
							<Box className='home-text'>
								<h2 id='further-considerations'>Further considerations</h2>
								<ul>
									<li>
										PSTN services utilised across Sipcom's HALO platform must
										have E911/PIDF-LO enabled for the service to work correctly
									</li>
									<li>
										Most remote users will have dynamic IP addresses at the home
										addresses so it wouldn't be recommended to configure these
										as “Trusted IPs”
									</li>
									<li>
										Enterprises need to consider that training may be required
										so that users know to update their location manually in the
										client when outside of the corporate networks. This will not
										be provided by Sipcom
									</li>
									<li>
										Changes to dynamic E911 configuration can take up to 4 hours
										to propagate. This needs to be considered when testing
										functionality is working correctly
									</li>
									<li>IPv6 subnets take precedence over IPv4 subnets</li>
									<li>
										This guide does not show you how to configure emergency call
										routing policies at a network site level, but if this is
										configured it will take precedence over the user's emergency
										call routing policy
									</li>
									<li>
										The elements described can also be configured by PowerShell
									</li>
								</ul>
							</Box>
							{/* Glossary */}
							<Box className='home-text'>
								<h2 id='glossary'>Glossary</h2>
								<Glossary />
							</Box>
							<Box className='home-text'>
								<h2 id='appendix-1'>
									Appendix 1 - Example of user experience for a call using
									dynamic E911
								</h2>
								{/* Image */}
								<Box className='image-container'>
									<img
										src={Image11}
										alt='Example of user experience for a call using dynamic E911'
									/>
								</Box>
							</Box>
							<Box className='home-text'>
								<h2 id='appendix-2'>
									Appendix 2 - Client showing in a known location
								</h2>
								{/* Image */}
								<Box className='image-container'>
									<img src={Image12} alt='Client showing in a known location' />
								</Box>
							</Box>
							<Box className='home-text'>
								<h2 id='appendix-3'>
									Appendix 3 - Client in an unknown location
								</h2>
								{/* Image */}
								<Box className='image-container'>
									<img src={Image13} alt='Client in an unknown location' />
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			{/* Modal */}
			{/* Modal Forms */}
			<Modal open={openModal} onClose={handleCloseModal}>
				<Box sx={modalStyle} className='modal-image'>
					{imageToEnlarge.length > 0 ? (
						<img
							src={require(`../../assets/images/${imageToEnlarge}`)}
							alt='Dynamic E911 for Direct Routing'
						/>
					) : null}
				</Box>
			</Modal>
		</>
	)
}

export default Home
